import socketClient from "socket.io-client";

// api/stream.js
import historyProvider from './historyProvider.js'
// we use Socket.io client to connect to cryptocompare's socket.io stream
// var io = require('socket.io-client')
// var socket_url = 'wss://streamer.cryptocompare.com'
// var socket = io(socket_url)
// keep track of subscriptions

var _subs = []

const socket = socketClient(`https://stream.coinpass.co.kr/?data=KRW-BTC`, {
  cors: { origin: '*', methods: ["GET", "POST"], credentials: true }
});

export default {
 subscribeBars: function(symbolInfo, resolution, updateCb, uid, resetCache) {
  const channelString = createChannelString(symbolInfo)
  socket.emit('SubAdd', {subs: [channelString]})
  
  var newSub = {
   channelString,
   uid,
   resolution,
   symbolInfo,
   lastBar: historyProvider.history[symbolInfo.name].lastBar,
   listener: updateCb,
  }
_subs.push(newSub)
 },
 unsubscribeBars: function(uid) {
  var subIndex = _subs.findIndex(e => e.uid === uid)
  if (subIndex === -1) {
   //console.log("No subscription found for ",uid)
   return
  }
  var sub = _subs[subIndex]
  socket.emit('SubRemove', {subs: [sub.channelString]})
  _subs.splice(subIndex, 1)
 }
}

socket.on('connect', () => {
  console.log('===Socket connected')
})
socket.on('disconnect', (e) => {
  console.log('===Socket disconnected:', e)
 })
 socket.on('error', err => {
  console.log('====socket error', err)
 })

socket.on('/ticker', (e) => {
  console.log("match data: ", e);
  console.log("match code: ", e.market);

  const data = {
    sub_type: parseInt(0, 10), // ???
    exchange: "UPBIT", // 거래소이름
    to_sym: e.market.split("-")[1], // coinTicker
    from_sym: e.market.split("-")[0], // market
    // trade_id: _data[5],
    trade_id: "",
    ts: e.timestamp / 1000, // timestamp
    volume: e.trade_volume, // 거래량
    price: e.trade_price, // 가격
    change: e.change
  };

  const channelString = `${data.sub_type}~${data.exchange}~${data.to_sym}~${data.from_sym}`;
  //console.log("1 ", channelString);
  //console.log("2 ", e.channelString);
  const sub = _subs.find(e => e.channelString === channelString);

  //console.log("sub ", sub);

  if (sub) {
    // disregard the initial catchup snapshot of trades for already closed candles
    console.log("volume-거래량 ", data.volume);
    console.log("price-가격 ", data.price);
    
    console.log("timestamp ", data.ts);
    console.log("lastBartime ", sub.lastBar.time / 1000);
    console.log("change ", data.change);
    
    if (data.ts < sub.lastBar.time / 1000) {
      return;
    }

    var _lastBar = updateBar(data, sub);

    // send the most recent bar back to TV's realtimeUpdate callback
    sub.listener(_lastBar);
    // update our own record of lastBar
    sub.lastBar = _lastBar;
  }
});

// Take a single trade, and subscription record, return updated bar
function updateBar(data, sub) {
  var lastBar = sub.lastBar;
  let resolution = sub.resolution;

  // resolution이 일(day) 또는 주(week)일 경우 분 단위로 변환
  if (resolution.includes('D')) {
    resolution = 1440; // 1일은 1440분
  } else if (resolution.includes('W')) {
    resolution = 10080; // 1주는 10080분
  }

  // 최신 버전에서 10초 간격으로 설정하려면 여전히 해당 단위로 변환
  var coeff = resolution * 60; // resolution 값을 초로 변환 (1분 = 60초)
  var rounded = Math.floor(data.ts / coeff) * coeff;
  var lastBarSec = lastBar.time / 1000;
  var _lastBar;

  // 새로운 봉 생성 시점인지 확인
  if (rounded > lastBarSec) {
    _lastBar = {
      time: rounded * 1000, // 타임스탬프를 밀리초 단위로 변환
      open: lastBar.close,  // 이전 봉의 종가를 새로운 봉의 시가로 설정
      high: lastBar.close,
      low: lastBar.close,
      close: data.price,    // 새로운 데이터의 가격을 종가로 설정
      volume: data.volume   // 새로운 데이터의 거래량 설정
    };
  } else {
    // 기존 봉 업데이트
    if (data.price < lastBar.low) {
      lastBar.low = data.price;
    }
    if (data.price > lastBar.high) {
      lastBar.high = data.price;
    }
    lastBar.volume += data.volume;
    lastBar.close = data.price;
    _lastBar = lastBar;
  }

  return _lastBar;
}


// takes symbolInfo object as input and creates the subscription string to send to CryptoCompare
function createChannelString(symbolInfo) {
  var channel = symbolInfo.name.split(/[:/]/)
  const exchange = channel[0] === 'GDAX' ? 'Coinbase' : channel[0]
  const to = channel[2]
  const from = channel[1]
 // subscribe to the CryptoCompare trade channel for the pair and exchange
  return `0~${exchange}~${from}~${to}`
}
