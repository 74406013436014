import * as React from 'react';
import './index.css';
import Datafeed from './api/'

function getLanguageFromURL() {
	const regex = new RegExp('[\\?&]lang=([^&#]*)');
	const results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export class TVChartContainer extends React.PureComponent {

	static defaultProps = {
		symbol: 'UPBIT:BTC/KRW',
		interval: '1',
		containerId: 'tv_chart_container',
		libraryPath: '/charting_library/',
		chartsStorageUrl: 'https://saveload.tradingview.com',
		chartsStorageApiVersion: '1.1',
		clientId: 'tradingview.com',
		userId: 'jshunk045@gmail.com',
		fullscreen: false,
		autosize: true,
		studiesOverrides: {},
		timezone: 'Asia/Seoul',
	};

	componentDidMount() {
		const widgetOptions = {
			debug: false,
			symbol: this.props.symbol,  // 사용하고자 하는 종목 (예: "BITHUMB:BTC/KRW")
			datafeed: Datafeed,  // 데이터 피드
			interval: this.props.interval,  // 차트 시간 간격 (예: 1분, 5분)
			container_id: this.props.containerId,  // 차트가 삽입될 HTML 컨테이너 ID
			library_path: this.props.libraryPath,  // 차트 라이브러리 경로
			locale: getLanguageFromURL() || 'ko',  // 로케일 설정 (한국어)
			timezone: this.props.timezone || "Asia/Seoul",  // 시간대 설정 (기본 UTC)
		  
			disabled_features: ['use_localstorage_for_settings'],  // 로컬 저장소 사용 안 함
			enabled_features: ['study_templates'],  // 연구 템플릿 기능 사용
		  
			charts_storage_url: this.props.chartsStorageUrl,  // 차트 저장소 URL
			charts_storage_api_version: this.props.chartsStorageApiVersion,  // 저장소 API 버전
			client_id: this.props.clientId,  // 클라이언트 ID
			user_id: this.props.userId,  // 사용자 ID
			fullscreen: this.props.fullscreen,  // 전체 화면 모드 설정
			autosize: this.props.autosize,  // 자동 크기 조정 설정
			studies_overrides: this.props.studiesOverrides,  // 연구 템플릿 설정
		  
			overrides: {
				// 차트 캔들 색상 설정
				"mainSeriesProperties.candleStyle.upColor": "#e15241",       // 상승 캔들 색상 (빨간색)
				"mainSeriesProperties.candleStyle.downColor": "#4880ee",     // 하락 캔들 색상 (파란색)
				"mainSeriesProperties.candleStyle.borderUpColor": "#e15241", // 상승 캔들 테두리 색상 (빨간색)
				"mainSeriesProperties.candleStyle.borderDownColor": "#4880ee", // 하락 캔들 테두리 색상 (파란색)
				"mainSeriesProperties.candleStyle.wickUpColor": "#e15241",   // 상승 캔들 꼬리 색상 (빨간색)
				"mainSeriesProperties.candleStyle.wickDownColor": "#4880ee", // 하락 캔들 꼬리 색상 (파란색)
			
				// 차트 배경 및 그리드 설정
				"paneProperties.background": "#FFFFFF",  // 차트 배경을 흰색으로 설정
				"paneProperties.vertGridProperties.color": "#E6E6E6",  // 세로 그리드 색상 (연한 회색)
				"paneProperties.horzGridProperties.color": "#E6E6E6",  // 가로 그리드 색상 (연한 회색)
			
				// 축 및 텍스트 설정
				"scalesProperties.textColor": "#000000",  // 축 텍스트 색상 (검정색)
				
				// 가격 축의 최소 이동 단위 및 소수점 설정
				"priceScale.minMove": 1,  // 가격 이동 단위를 1로 설정 (소수점 없이)
				"priceScale.precision": 0,  // 소수점 이하 자릿수 0으로 설정
				"mainSeriesProperties.priceFormat.formatter": function(price) {
					return price.toLocaleString('ko-KR', {style: 'currency', currency: 'KRW', minimumFractionDigits: 0});
				}
			  },
			  // 거래량 봉 색상 설정
				studies_overrides: {
					"volume.volume.color.0": "#4880ee",  // 하락 거래량 봉 색상 (파란색)
					"volume.volume.color.1": "#e15241",  // 상승 거래량 봉 색상 (빨간색)
					"volume.volume.transparency": 70,    // 거래량 봉 투명도
				}
		  };
		  
		Datafeed.onReady(() => {
            const widget = (window.tvWidget = new window.TradingView.widget(widgetOptions));

            widget.onChartReady(() => {
                console.log('Chart has loaded!')
				
            });
        });
	}

	render() {
		return (
			<div
				id={ this.props.containerId }
				className={ 'TVChartContainer' }
			/>
		);
	}
}
